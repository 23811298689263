




import Vue from 'vue';
import { vuetifyComponents } from '@/plugins/vuetify';
export default Vue.extend({
  components: {
    ...vuetifyComponents,
  },
  methods: {
    click() {
      if (this.confirmMessage) {
        if (confirm(this.confirmMessage)) {
          this.onClick();
        }
      } else {
        this.onClick();
      }
    },
  },
  props: {
    color: String,
    confirmMessage: String,
    disabled: Boolean,
    errorMessage: String,
    icon: String,
    label: String,
    loadingLabel: String,
    loading: Boolean,
    onClick: { type: Function, required: true },
  },
});
